import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

function App() {


  const [loading, setLoading] = useState(true)
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    if (window.navigator && window.navigator.bluetooth) {
      setLoading(false)
      setEnabled(true)
    }
  }, [])
  const readDeviceNameValue = async (characteristic) => {
    const value = await characteristic.readValue();
    console.log('> Device Name: ' + new TextDecoder().decode(value));
  }


  const readAppearanceValue = async (characteristic) => {


    const value = await characteristic.readValue();
    console.log(value)
    let len = value.byteLength;
    var arr_res = new Array(len);
    
    //ChromeSamples.setStatus('> Received BLE Data,size=' + len);
    console.log(' > Received BLE Data,size=' + len);
    for (var i = 0; i < len; i++) {
      arr_res[i] = value.getUint8(i, true);
    }
    console.log(arr_res)
    // arr_res[2] = 14
    // await characteristic.writeValueWithoutResponse(Uint8Array.from(arr_res))
    // console.log("wrote data....")
  }

  let eq_characteristic

  const onConnect = async () => {
    try {

      const serviceUUid = ('e093f3b5-00a3-a9e5-9eca-40016e0edc24')
      const characteristicUuid = 'e093f3b5-00a3-a9e5-9eca-40036e0edc24'
      // const alreadyPaired = await navigator.bluetooth.getDevices().then((devicess) => {
      //   console.log(devicess)
      // }).catch(() => {
      //   console.error('devices not found')
      // })
      // console.log(alreadyPaired)
      const device = await navigator.bluetooth.requestDevice({ acceptAllDevices: true, optionalServices: [serviceUUid] }).catch((e) => {
        console.log(e)
      })

      console.log("connected device", device)
      const server = await device.gatt.connect();
      console.log("connected server")
      const service = await server.getPrimaryService(serviceUUid);
      console.log("connected service")
      const characteristics = await service.getCharacteristics();
      console.log(characteristics)



      for (const characteristic of characteristics) {

        switch (characteristic.uuid) {

          case window.BluetoothUUID.getCharacteristic('gap.appearance'):
            await readAppearanceValue(characteristic);
            break;

          case window.BluetoothUUID.getCharacteristic('gap.device_name'):
            await readDeviceNameValue(characteristic);
            break;
          case 'e093f3b5-00a3-a9e5-9eca-40036e0edc24':
            eq_characteristic = characteristic;
            await readAppearanceValue(characteristic);
            break;

          default:
            //log('> Unknown Characteristic: ' + characteristic.uuid);
            //await readDeviceNameValue(characteristic);
            break;

        }
      }

    } catch (e) {
      console.log(e.message)
      // alert(e.message)
    }
  }
  return (
    <>
      {
        loading && "Checking ..."
      }
      {
        enabled ? <>
          <button onClick={onConnect}>
            Connect
          </button>
        </> : <>
          na chale ama
        </>
      }
    </>
  );
}

export default App;
